<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 서류분류 -->
          <c-select
            type="search"
            codeGroupCd="VENDOR_ATTACH_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="vendorAttachTypeCd"
            label="서류분류"
            v-model="searchParam.vendorAttachTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 등록기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-1M"
            defaultEnd="1M"
            label="등록기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="vendorTable"
      title="서류 목록"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      selection="multiple"
      :isExcelDown="false"
      rowKey="mdmVendorAttachId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addAttach" />
          <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleteAttach" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSaveAttach"
            :url="saveAttachUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveAttach"
            @btnCallback="saveAttachCallback" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-attach',
  props: {
  },
  data() {
    return {
      searchParam: {
        vendorAttachTypeCd: null,
        period: [],
        vendorCd: '',
      },
      grid: {
        data: [],
        columns: [],
      },
      editable: true,
      isSaveAttach: false,
      listAttachUrl: '',
      saveAttachUrl: '',
      deleteAttachUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listAttachUrl = selectConfig.mdm.cim.vendor.attach.list.url;
      this.saveAttachUrl = transactionConfig.mdm.cim.vendor.attach.save.url;
      this.deleteAttachUrl = transactionConfig.mdm.cim.vendor.attach.delete.url;
      // code setting
      this.$comm.getComboItems('VENDOR_ATTACH_TYPE_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'vendorAttachTypeCd',
            field: 'vendorAttachTypeCd',
            label: '서류분류',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            required: true,
            type: 'select',
            comboItems: _result,
          },
          {
            name: 'title',
            field: 'title',
            label: '제목',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            required: true,
            type: 'text',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'textarea',
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '등록일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '첨부파일',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'VENDOR_ATTACH',
            keyText: 'mdmVendorAttachId',
            sortable: false,
          }
        ];
      });
      this.searchParam.vendorCd = this.$store.getters.user.userId;
      this.getList();
    },
    getList() {
      // 상세조회
      this.$http.url = this.listAttachUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },
      () => {
      });
    },
    addAttach() {
      this.grid.data.splice(0, 0, {
        mdmVendorAttachId: uid(),  // 도급업체 첨부파일 일련번호
        vendorCd: this.$store.getters.user.userId,  // 업체코드
        vendorAttachTypeCd: null,  // 업체별 첨부파일 구분 코드
        title: '',  // 업체 첨부파일 제목
        remark: '',  // 업체 첨부파일 비고
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
    },
    deleteAttach() {
      let selectData = this.$refs[`vendorTable`].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteAttachUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs[`vendorTable`].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveAttach() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveAttach = !this.isSaveAttach
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveAttachCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
